import React, { MouseEventHandler } from 'react';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useIsToss } from '@common/device-manager';

import { BaseTopNavigation } from '../../base';
import type { ZDSComponentCommonProps } from '../../utils/types';
import { ZigzagLogo } from '../common/ZigzagLogo';
import { useZigzagTopNavigationContext } from '../ZigzagTopNavigationProvider';

declare const window: Window & {
  __TOSS_API__?: {
    closeWebView: () => void;
  };
};

export interface ZigzagTopNavigationHomeProps extends ZDSComponentCommonProps {
  /**
   * @description transparent 옵션을 사용하면 배경색이 투명하게 처리됩니다. (기획전 등 배경이 이미지인 경우 사용)
   * @default false
   */
  transparent?: boolean;
  children?: React.ReactNode;

  onLogoClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const rootCss = css`
  &.transparent {
    background-color: ${vars.color.transparent};
  }
`;

const leftGroupCss = css`
  margin-left: 16px;
  margin-right: 10px;
`;

const rightGroupCss = css`
  margin-left: auto;
  margin-right: 6px;
`;

export const ZigzagTopNavigationHome = React.forwardRef(function ZigzagTopNavigationHome(
  props: ZigzagTopNavigationHomeProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const isToss = useIsToss();
  const { className, transparent, children, onLogoClick } = props;
  const router = useRouter();
  const context = useZigzagTopNavigationContext();

  const logo = context.logo ?? <ZigzagLogo />;
  const homeHref = '/';

  const handleLogoClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    onLogoClick?.(e);

    // MEMO: 토스 전역 대응을 위한 임시 처리
    if (isToss && window.__TOSS_API__) {
      if (window.__TOSS_API__) {
        window.__TOSS_API__.closeWebView();
        return true;
      }
    }

    router.push(homeHref);
  };
  return (
    <BaseTopNavigation.Root css={rootCss} ref={ref} className={clsx([className, transparent])}>
      {/* left */}
      <BaseTopNavigation.Group css={leftGroupCss}>
        <a aria-label='홈' href={homeHref} onClick={handleLogoClick}>
          {logo}
        </a>
      </BaseTopNavigation.Group>
      {/* right */}
      <BaseTopNavigation.Group css={rightGroupCss}>{children}</BaseTopNavigation.Group>
    </BaseTopNavigation.Root>
  );
});
